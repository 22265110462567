import { computed } from "vue";

export function useConditions() {
  const { $pinia } = useNuxtApp();

  const discountStore = useDiscountStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const { trialDays, checkoutParameters } = useCheckout();

  const hasFreeTrial = computed<boolean>(() => trialDays.value > 0);
  const hasDiscountCode = computed<boolean>(() => discountStore.code !== "");
  const hasSkippedFunnel = computed<boolean>(() => onboardingStore.skippedOnboarding);
  const hasGoalMaintain = computed<boolean>(() => onboardingStore.registrationParams.goal === "maintain");
  const hasGoalLose = computed<boolean>(() => onboardingStore.registrationParams.goal === "lose");
  const hasGoalGain = computed<boolean>(() => onboardingStore.registrationParams.goal === "gain");
  const choosePlanTrial = computed<boolean>(() => hasFreeTrial.value && !hasDiscountCode.value);
  const isCountryWithTaxRequirements = computed<boolean>(() => {
    if (typeof checkoutParameters.value === "boolean") {
      return false;
    }
    return ["US", "CA"].includes(checkoutParameters.value.country);
  });

  return {
    hasFreeTrial,
    hasDiscountCode,
    hasSkippedFunnel,
    hasGoalMaintain,
    hasGoalLose,
    hasGoalGain,
    choosePlanTrial,
    isCountryWithTaxRequirements,
  };
}
